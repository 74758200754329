import axios from 'axios';

import {
    HANDLE_OPT_IN,
    LOAD_BOOTSTRAP_DATA,
    LOAD_REPLIES_DATA,
    VITE_JIT_LOGIN_URL,
    VITE_REQUEST_OTP_URL,
    VITE_VERIFY_OTP_URL,
} from 'constants/env.js';
import { addParamsToUrl } from 'utils/common/common';

export const fetchJitAccessTokenAPI = async () => {
    const response = await axios.post(VITE_JIT_LOGIN_URL, {
        is_guest: true,
        partner_id: 9,
    });
    return response.data;
};

export const fetchJitAccessTokenAPINew = async ({ partnerId }) => {
    const x = axios
        .get('https://clarus-configs.s3.amazonaws.com/clarus-wma/sample.json')
        .then(response => response.data);
    return axios.post(VITE_JIT_LOGIN_URL, {
        is_guest: true,
        partner_id: partnerId,
    });
};

export const getPartnerAPI = async ({ accessToken, partnerId, requestId }) => {
    return axios.get(
        addParamsToUrl(LOAD_BOOTSTRAP_DATA, {
            partner_short_id: partnerId,
            request_id: requestId,
        }),
        {
            // headers: {
            //     Authorization: `Bearer ${accessToken}`,
            // },
        }
    );
};

export const requestOTPAPI = async ({ phoneNumber, isRefresh }) => {
    const response = await axios.post(VITE_REQUEST_OTP_URL, {
        type: 'wma-otp',
        action: isRefresh ? 'refresh' : 'new',
        phone_number: phoneNumber,
    });
    return response.data;
};

export const verifyOTPAPI = async ({ otp, phone_number }) => {
    const response = await axios.post(VITE_VERIFY_OTP_URL, {
        otp,
        phone_number,
    });
    return response.data;
};

export const handleOptInStatus = async ({ status, phone_number }) => {
    const response = await axios.post(HANDLE_OPT_IN, {
        phone_number,
        status,
    });
    return response.data;
};

export const fetchRepliesData = async callId => {
    const response = await axios.get(
        LOAD_REPLIES_DATA.replace('{callId}', callId)
    );
    return response.data;
};
