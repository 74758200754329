import axios from 'axios';

import { VITE_CREATE_CALL_URL, VITE_PARTNER_URL } from 'constants/env.js';

export const createCallAPI = async ({
    partner_id,
    patient_dob,
    patient_message,
    provider_id,
    callback_number,
    patient_name,
    token,
}) => {
    const response = await axios.post(
        VITE_CREATE_CALL_URL,
        {
            source: 'wma',
            type: 'message',
            partner_id,
            patient_dob,
            patient_message,
            provider_id,
            callback_number,
            patient_name,
            is_urgent: false,
        },
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );

    return response.data;
};

export const getCallAPI = async ({ accessToken, partnerId, callId }) => {
    return axios.get(`${VITE_PARTNER_URL}${partnerId}/calls/${callId}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
};
