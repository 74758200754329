import React from 'react';

import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import ApiProvider from 'modules/Api/ApiContext';
import i18n from 'modules/Translation/i18n';
import { AppProvider } from 'store/AppContext';

import './index.css';

const Root = React.lazy(() => import('containers/Root'));

ReactDOM.createRoot(document.getElementById('root')).render(
    <AppProvider>
        <ApiProvider>
            <BrowserRouter>
                <I18nextProvider i18n={i18n}>
                    <Root />
                </I18nextProvider>
            </BrowserRouter>
        </ApiProvider>
    </AppProvider>
);
