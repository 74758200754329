import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: {
                hello: 'Hello!',
                dob: 'Date of Birth',
                911: 'If this is a life-threatening emergency, dial 911 or go to your nearest emergency room.',
                mainMenu: 'Main Menu',
                providerReply: 'Provider Reply',
                enterPhoneNumber: 'Phone Number',
                iAgreePhone:
                    'I agree to receive pass codes and web links via SMS',
                identityVerification: 'OTP Verification',
                didNotReceiveOTP: "Didn't receive the OTP?Resend OTP",
                cancel: 'Cancel',
                submit: 'Submit',
                verifyIdentity:
                    'Please verify your identify by entering the one time pass code sent to your mobile phone.',
                smsRates:
                    'Standard text messaging and data rates may apply. To unsubscribe from SMS updates, reply STOP. To start receiving SMS updates, reply START. For instructions, reply HELP.',
                requestPhoneTitle:
                    'To submit a request and receive updates from your provider, Clarus will send you a one time pass code when you need to verify your mobile device and a web link whenever your provider replies to a request.',
                privacy: 'Privacy',
                TOS: 'TOS',
                expiredMessageTitle: 'Expired',
                expiredMessageForMutiple: 'The replies will expire',
                expiredMessageForMutipleSecond: 'minutes after being read.',
            },
        },
        es: {
            translation: {
                hello: 'Hola!',
                dob: 'Fecha de Nacimiento',
                911: 'Si se trata de una emergencia que pone en peligro la vida, llame al 911 o vaya a la sala de emergencias más cercana.',
                mainMenu: 'Menú Principal',
                providerReply: 'Respuesta del proveedor',
                enterPhoneNumber: 'Número de teléfono',
                iAgreePhone:
                    'Acepto recibir códigos de acceso y enlaces web por SMS',
                identityVerification: 'Verificación OTP',
                didNotReceiveOTP: '¿No recibiste la OTP?Reenviar OTP',
                cancel: 'Cancelar',
                submit: 'Entregar',
                verifyIdentity:
                    'Verifique su identidad ingresando el código de acceso de un solo uso enviado a su teléfono móvil.',
                smsRates:
                    'Se pueden aplicar tarifas estándar de mensajes de texto y datos. Para cancelar la suscripción a las actualizaciones por SMS, responda DETENER. Para comenzar a recibir actualizaciones por SMS, responda INICIAR. Para obtener instrucciones, responda AYUDA.',
                requestPhoneTitle:
                    'Para enviar una solicitud y recibir actualizaciones de su proveedor, Clarus le enviará un código de acceso único cuando necesite verificar su dispositivo móvil y un enlace web cada vez que su proveedor responda a una solicitud.',
                privacy: 'Privacidad',
                TOS: 'TdS',
                expiredMessageTitle: 'Vencido',
                expiredMessageForMutiple: 'Las respuestas expirarán',
                expiredMessageForMutipleSecond:
                    'minutos después de ser leídas.',
            },
        },
    },
    lng: 'en',
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
