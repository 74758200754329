import React, { createContext, useContext, useMemo } from 'react';

import _get from 'lodash/get';

import { fetchJitAccessTokenAPINew, getPartnerAPI } from './auth';
import { getCallAPI } from './call.js';

const ApiContext = createContext();

export const useApi = () => {
    return useContext(ApiContext);
};

// eslint-disable-next-line react/prop-types
function ApiProvider({ children }) {
    const fetchData = async params => {
        try {
            const { partnerId, requestId } = params;
            // const response = await fetchJitAccessTokenAPINew({ partnerId });
            // const accessToken = _get(response, 'data.token', '');
            const {
                data: { data },
            } = await getPartnerAPI({
                partnerId,
                requestId,
            });
            return {
                // accessToken,
                phoneNumber: data?.call_detail?.callback_number || '',
                ...data,
            };
        } catch (error) {
            const errorMsg =
                (error?.response?.data?.data?.partner_short_id?.join('\n') ||
                    '') +
                (error?.response?.data?.data?.request_id?.join('\n') || '');
            throw errorMsg || error.message;
        }
    };

    const fetchCallDetails = async (partnerId, callId) => {
        try {
            const response = await fetchJitAccessTokenAPINew({ partnerId });
            const accessToken = _get(response, 'data.token', '');
            // const partner = await getPartnerAPI({ accessToken, partnerId });
            const call = await getCallAPI({ accessToken, partnerId, callId });
            return _get(call, 'data', {});
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Error fetching data:', error);
            throw error;
        }
    };
    const apiValue = useMemo(() => ({ fetchData, fetchCallDetails }), []);

    return (
        <ApiContext.Provider value={apiValue}>{children}</ApiContext.Provider>
    );
}

export default ApiProvider;
