const { VITE_API_PREFIX } = import.meta.env;

const apiPrefix = `${VITE_API_PREFIX}/api`;

export const VITE_JIT_LOGIN_URL = `${apiPrefix}/v4/auth/jit-login`;
export const VITE_REQUEST_OTP_URL = `${apiPrefix}/v1/messages`;
export const VITE_VERIFY_OTP_URL = `${apiPrefix}/v1/otp-verifications`;
export const VITE_PARTNER_URL = `${apiPrefix}/v1/bootstrap`;
export const VITE_CREATE_CALL_URL = `${apiPrefix}/v3/calls`;
export const LOAD_BOOTSTRAP_DATA = `${apiPrefix}/v1/bootstrap`;
export const LOAD_REPLIES_DATA = `${apiPrefix}/v1/calls/{callId}/responses`;
export const HANDLE_OPT_IN = `${apiPrefix}/v1/profiles`;

export const COOKIE_CALLBACK_NUMBER = 'wma_cookie_callback_number';
export const OPT_IN_CAMPAIGNS = ['wma-otp', 'wma-link'];
export const MAX_OTP_ATTEMPTS = 3;
export const ROUTE_404 = '/Page404';